import { useLocation, useNavigate } from "react-router-dom";
import Header from "components/header/HeaderAuthDefault";
import httpService from "services/http-service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";
import { emailMaskGenerator } from "utils/utils";
import InputField from "components/fields/InputField";

const emailMask = "*********@****.***";
const maskGenerator = emailMaskGenerator(emailMask);

export default function SignIn() {
  
  const navigate = useNavigate();
  const location = useLocation();
  const [isRegular, setIsRegular] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const email = urlParams.get('email');
    if (email) {
      localStorage.setItem('userEmail', email);
      setPhoneNumber(email);

      handleLogin(email);

    }
  }, [location]);

  
  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    setPhoneNumber(inputValue);

    // Email syntax validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailPattern.test(inputValue));
  };

 

  

  const handleRouteClick = (page) => {
    navigate(page); // Navigate to specific page
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  

  const handleLogin = async (email = '') => {
    if (phoneNumber || email) {
      setLoading(true);
      localStorage.setItem('userEmail', JSON.stringify(phoneNumber  || email))
      const endpoint = `Verification/EmailVerificationCode/${phoneNumber  || email}`
      const response = await httpService("POST", endpoint, {});
      if (!response) {
        setLoading(false);
        toast.error(response.message || 'Error Logging in');
      } else {
        if (response) {
          setLoading(false);
          localStorage.setItem("userDetails", JSON.stringify(response));
          if (response.accountSetupStatus !== 'Completed') {
            toast.success("You are logged in Successfully");

          } else {
            toast.success("Spots SMS code has been sent");

          }
          navigate("/auth/verify", {
            state: {
              isRegular: isRegular,
            },
          });
        }
      }
    }
  };

 

  return (
    <div className="flex pt-2 h-full w-full justify-center px-0 lg:justify-start">
      <Loading loading={loading} loaderColor="#252424" />
      {/* Sign in section */}
      <div className="mt-0 w-full max-w-full flex-col items-center">
        <Header
          tagline={ 
              "Go out, Find your crowd"
          }
        />
       
        <h4 className="mb-2.5 mt-6 text-2xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="text-base font-medium text-navy-700 dark:text-white">
        We’ll send a code to this email if you have an existing account
        </p>
        {/* Email */}
        <InputField
          autoFocus
          className={`dark:text-black mt-4 flex h-12 w-full items-center justify-center rounded-xl border ${isValid ? 'border-gray-200' : 'border-red-500' // Applying border color based on validation
            } p-3 text-sm outline-none ${isValid ? 'dark:!border-white/10' : '' // Additional style if valid
            }`}
          placeholder="Your Email"
          inputMode="email"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
        {!isValid && (
          <p className="text-red-500 text-xs mt-1">Please enter a valid email address</p>
        )}
        <button
          onClick={() => handleLogin()}
          className={
            phoneNumber.length > 2
              ? "linear mt-10 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              : "linear mt-10 w-full cursor-not-allowed rounded-xl bg-gray-500 py-[12px] text-base font-medium text-white opacity-50 transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          }
        >
          Continue
        </button>
 
        <div className="signIn-notes mt-8">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
          You're one connection away from achieving your next goal.
          </span>
      
        </div>
        
      </div>
    </div>
  );
}
