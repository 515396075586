import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Loading from "react-fullscreen-loading";
import { MdOutlineSettings, MdAutorenew, MdArrowBackIos } from "react-icons/md";
import { toast } from "react-toastify";
import httpService from "services/http-service";
import { getLocalStorageKey } from "utils/utils";
import Header from "components/header/HeaderAuthDefault";
import { IoMdHeartEmpty } from "react-icons/io";
import { MdOutlineClose, MdOutlineQrCodeScanner } from 'react-icons/md';
import Sheet from "react-modal-sheet";
import { FaInstagram, FaLinkedin, FaRegPaperPlane } from "react-icons/fa";


import UserProfile from "./UserProfile";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Notifications from "./Notification";
import EventScan from "./Eventscan";

export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [sponsors, setSponsors] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [vipUsers, setVipUsers] = useState([]);
  const [waveClicked, setWaveClicked] = useState(false);
  const [otherUsers, setOtherUsers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showReactions, setShowReactions] = useState(false);
  const [reactionClicked, setReactionClicked] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [showEventScan, setShowEventScan] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);


  const handleQRCodeIconClick = () => {
      setShowEventScan(true);
  };

  const handleCloseEventScan = () => {
      setShowEventScan(false);
  };

  const handleClick = () => {
      if (!reactionClicked) {
          setShowReactions(true);
      }
  };
  const handleReactionClick = () => {
      setShowReactions(false);
      setReactionClicked(true);
  };
  const handleWaveIconClick = () => {
      if (!reactionClicked) {
          setShowReactions(false);
          setReactionClicked(false);
      }
  };

  const handleWaveClick = () => {
      setWaveClicked(true);
      toast.success("We'll let them know you've waved.");
  };

  const toggleNotifications = () => {
      setShowNotifications(!showNotifications);
  };

  const toggleDropdown = () => {
      setShowDropdown(!showDropdown);
  };

  useEffect(() => {
      // Add event listener to handle outside click
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          // Cleanup the event listener
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, []);

  const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowDropdown(false); // Close the dropdown if clicked outside of it
      }
  };

  const selectUserProfile = (eventData) => {
      setSelectedUser(eventData);
      localStorage.setItem('selectedUserId', eventData?.userId); // will remove
      setSelectedUserId(eventData?.userId);
      setIsOpen(true);
  };

  const closeUserProfile = () => {
      setSelectedUser(null);
      setIsOpen(false); // Close the sheet when user profile is closed
  };





  const handleOptionClick = (option) => {
      // Handle click action based on the selected option
       if (option === "settings") {
          navigate('/auth/edit-profile', { state: { fromEventsPage: true } }); // Redirect to edit profile page
      } else if (option === "logout") {
          localStorage.clear();
          navigate('/auth/sign-in')
      }else   {
         navigate(option)
    }
  };

 

  const updateEventData = (eventData) => {
      setSponsors(eventData?.sponsors);
      setHosts(eventData?.hosts);
      setVipUsers(eventData?.vipUsers);
      setOtherUsers(eventData?.otherUsers);
  };

  // Fetch data and check for notifications when the component mounts
  useEffect(() => {
      populateData();

      const intervalId = setInterval(() => {
          populateData(); // Call populateData() every 90 seconds
      }, 90 * 1000); // 90 seconds in milliseconds

      return () => clearInterval(intervalId);
  }, []);

  const populateData = async () => {
      setLoading(true);
      // Fetch data from server
      // Update state variables
      setLoading(false);

      // Calculate hasNotifications based on fetched data
      // For demonstration, let's assume there are notifications
      const hasNotifications = true;

      // Update the local state
      setHasNotifications(hasNotifications);
  };

  return (
      <>
         <div className="fixed top-0 left-0 w-full z-50 bg-white">
      <div className="flex relative mt-3 ml-2 mb-2 mr-2">
        <Header className="sticky top-0 bg-white shadow-md border-b border-gray-200" />
        <div className="mt-2 mr-2 text-2xl transition duration-300 hover:text-gray-500 cursor-pointer" onClick={toggleNotifications}>
        {hasNotifications && <div className="absolute top-2 right-8 mr-1 h-3 w-3 bg-red-500 rounded-full"></div>}
          <IoMdHeartEmpty />
        </div>
        {/* Full Page Popup for Notifications */}
        {showNotifications && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 h-full w-full max-h-screen overflow-y-auto">
              <button className="absolute top-0 right-0 p-2" onClick={toggleNotifications}>
                <MdOutlineClose className="h-6 w-6" />
              </button>
              <Notifications setHasNotifications={setHasNotifications}  />
            </div>
          </div>
        )}
        <div
          className="mt-2 mr-2 text-2xl transition duration-300 hover:text-gray-500 cursor-pointer"
          onClick={toggleDropdown}
        >
          <MdOutlineSettings />
        </div>

        {showDropdown && (
          <div ref={dropdownRef} className="absolute right-0 mt-10 w-48 bg-white rounded-md shadow-lg z-50">
            <div className="py-1">
              <button
                onClick={() => handleOptionClick("settings")}
                className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-200"
              >
                Edit profile
              </button>
              <button
                onClick={() => handleOptionClick("/auth/myevents")}
                className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-200"
              >
                My events
              </button>
              <button
                onClick={() => handleOptionClick("logout")}
                className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-200"
              >
                Logout
              </button>
            </div>
          </div>
        )}
      </div> 
              {/* Separation line */}
              <div className="border-t border-gray-200"></div>       
    </div>

    <div className="flex overflow-y-auto hide-scrollbar h-full w-full justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:justify-start">
      <Loading loading={loading} loaderColor="#252424" />
      <div className="ml-4 mt-14 w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div>
          <h4 className="text-1xl 700 mb-5 mt-5">
            Scan the spots QR code from the venue host.
          </h4>

        </div>
      </div>
      </div>
    </>      
  );  
}