import Header from "components/header/HeaderAuthDefault";
import React, { useEffect, useState } from "react";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";
import httpService from "services/http-service";
import { USStates } from "utils/common";

const AddEventForm = ({
    selectedEvent,
    closePopup
}) => {

    console.log(JSON.stringify(selectedEvent))
    const [eventTypes, setEventTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        id: 0,
        name: "",
        email: "",
        description: "",
        eventType: '',
        rating: 0,
        hasDancefloor: false,
        hasAlcohol: false,
        hasFood: false,
        hasHookah: false,
        website: "",
        phoneNumber: "",
        streetNumber: "",
        street: "",
        city: "",
        country: "",
        stateProvince: "",
        zipPostalCode: 0,
        ownerFullName: "",
        dressCode: "",
        ticketPriceStart: 0,
        ticketPriceEnd: 0,
        startTime: "",
        endTime: "",
        photo: "",
        isActive: false,
        latitude: "",
        longitude: "",
        eventSubTypeId: 1,
        sponsors: [
            {
                "name": "",
                "webSite": "",
                "photo": "",
                "base64": ""
            }
        ],
        "hosts": [
            {
                "name": "",
                "email": ""
            }
        ],
        "vipUsers": [
            {
                "name": "",
                "email": ""
            }
        ]
    });

    const bindDataToForm = (data) => {
        setFormData({
            id: selectedEvent?.id || 0,
            name: selectedEvent?.name || "",
            email: selectedEvent?.email || "", // Not present in the provided data
            description: selectedEvent?.description || "",
            eventType: selectedEvent?.eventType || "", // Assuming event type is derived from `eventEventSubType`

            website: selectedEvent?.website || "",
            phoneNumber: selectedEvent?.phoneNumber || "",
            streetNumber: selectedEvent?.streetNumber || "",
            street: selectedEvent?.street || "",
            city: selectedEvent?.city || "",
            country: selectedEvent?.country || "",
            stateProvince: selectedEvent?.stateProvince || "",
            zipPostalCode: parseInt(selectedEvent?.zipPostalCode, 10) || 0,
            ownerFullName: selectedEvent?.ownerFullName || "",


            startTime: selectedEvent?.startTime || "",
            endTime: selectedEvent?.endTime || "",
            photo: selectedEvent?.photo || "",
            isActive: selectedEvent?.isActive || false,
            latitude: selectedEvent?.latitude || "",
            longitude: selectedEvent?.longitude || "",
            eventSubTypeId: 1, // Default value as per your structure
            sponsors: selectedEvent?.sponsors.map((sponsor) => ({
                name: sponsor.name || "",
                webSite: sponsor.webSite || "",
                photo: sponsor.photo || "",
                base64: sponsor.base64 || "",
            })),
            hosts: selectedEvent?.hosts.map((host) => ({
                name: host.name || "",
                email: host.email || "",
            })),
            vipUsers: selectedEvent?.vipUsers.map((vip) => ({
                name: vip.name || "",
                email: vip.email || "",
            })),
        });
    };
    useEffect(() => {
        if (selectedEvent) {
            bindDataToForm();

        }

    }, [selectedEvent]);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    };
    useEffect(() => {
        populateData();

    }, []);

    const populateData = async () => {
        setLoading(true);
        const endpoint = `/Event/GetEventTypes`;
        const response = await httpService("GET", endpoint);

        if (!response) {
            setLoading(false);
            toast.error('Something went wrong');
        } else {
            setLoading(false);
            setEventTypes(response || [])
        }
    };

    const validateForm = () => {
        const newErrors = {};

        // Validate Event Name
        if (!formData.name.trim()) {
            newErrors.name = "Event name is required.";
        }

        // Validate Event Type
        if (!formData.eventType.trim()) {
            newErrors.eventType = "Event type is required.";
        }

        // Validate Start Time
        if (!formData.startTime) {
            newErrors.startTime = "Event date & time is required.";
        }

        // Validate City
        if (!formData.city.trim()) {
            newErrors.city = "City is required.";
        }

        // Validate State
        if (!formData.stateProvince.trim()) {
            newErrors.stateProvince = "State is required.";
        }

        // Validate Owner Full Name
        if (!formData.ownerFullName.trim()) {
            newErrors.ownerFullName = "Your first name is required.";
        }

        // Validate Email
        if (!formData.email.trim()) {
            newErrors.email = "Email is required.";
        } else if (
            !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.email)
        ) {
            newErrors.email = "Enter a valid email address.";
        }

        // Validate Phone Number
        if (!formData.phoneNumber.trim()) {
            newErrors.phoneNumber = "Phone number is required.";
        } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
            newErrors.phoneNumber = "Phone number must be 10 digits.";
        }


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     if (!validateForm()) return;

    //     console.log("Submitted Data:", formData);
    // };

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleArrayChange = (e, arrayName, index, fieldName) => {
        const { value } = e.target;
        setFormData((prevState) => {
            const updatedArray = [...prevState[arrayName]];
            updatedArray[index][fieldName] = value;
            return {
                ...prevState,
                [arrayName]: updatedArray,
            };
        });
    };

    const handleArrayFileChange = (e, arrayName, index, fieldName) => {
        const file = e.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result.split(",")[1];
            setFormData((prevState) => {
                const updatedArray = [...prevState[arrayName]];
                updatedArray[index][fieldName] = file.name; // Save file name
                updatedArray[index].base64 = base64String; // Save base64 string
                return {
                    ...prevState,
                    [arrayName]: updatedArray,
                };
            });
        };
        reader.readAsDataURL(file);
    };

    const addArrayItem = (arrayName, defaultItem) => {
        setFormData((prevState) => ({
            ...prevState,
            [arrayName]: [...prevState[arrayName], defaultItem],
        }));
    };

    const removeArrayItem = (arrayName, index) => {
        setFormData((prevState) => ({
            ...prevState,
            [arrayName]: prevState[arrayName].filter((_, i) => i !== index),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        console.log("Submitted Data:", formData);
        setLoading(true);
        const endpoint = "/Event";

        const response = await httpService("POST", endpoint, formData);
        if (typeof response === "number" && !isNaN(response)) {
             setLoading(false);
            toast.success("Event Added successfully");
            closePopup(true)
        } else {
            setLoading(false);
            toast.error(response.title || 'Error while saving');
     
        }
    };

    return (
        <div className="container mx-auto pb-14">

            <Loading loading={loading} loaderColor="#252424" />

            <form
                onSubmit={handleSubmit}
                className="max-w-7xl mt-14 mx-auto p-6 bg-white rounded-lg shadow-md w-full"
            >

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6">
                    {/* Name */}
                    <div>
                        <label className="block text-sm font-medium mb-1">
                            Event Name <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className={`w-full p-2 border ${errors.name ? "border-red-500" : "border-gray-300"
                                } rounded-md`}
                        />
                        {errors.name && (
                            <span className="text-red-500 text-sm">{errors.name}</span>
                        )}
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-1">Event Type  <span className="text-red-500">*</span> </label>
                        <select
                            name="eventType"
                            value={formData.eventType}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        >
                            <option value="">Select</option>
                            {eventTypes.map((eventType) => (
                                <option key={eventType.id} value={eventType.name}>
                                    {eventType.name}
                                </option>
                            ))}
                        </select>
                        {errors.eventType && (
                            <span className="text-red-500 text-sm">{errors.eventType}</span>
                        )}
                    </div>
                    <div  >
                        <label className="block text-sm font-medium mb-1">
                            Event Date & Time  <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="datetime-local"
                            name='startTime'
                            value={formData.startTime}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                        {errors.startTime && (
                            <span className="text-red-500 text-sm">{errors.startTime}</span>
                        )}
                    </div>

                    {[
                        "city"
                    ].map((field) => (
                        <div key={field}>
                            <label className="block text-sm font-medium mb-1">
                                {field === 'city' ? 'Event City' : 'Event State'} <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                name={field}
                                value={formData[field]}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                            {errors.city && (
                                <span className="text-red-500 text-sm">{errors.city}</span>
                            )}
                        </div>
                    ))}

                    <div>
                        <label className="block text-sm font-medium mb-1">Event State <span className="text-red-500">*</span></label>
                        <select
                            name="stateProvince"
                            value={formData.stateProvince}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        >
                            <option value="">Select a state</option>
                            {USStates.map((state) => (
                                <option key={state?.label} value={state?.label}>
                                    {state?.label}
                                </option>
                            ))}
                        </select>
                        {errors.stateProvince && (
                            <span className="text-red-500 text-sm">{errors.stateProvince}</span>
                        )}
                    </div>

                    <div>
                        <label className="block text-sm font-medium mb-1">Your First Name <span className="text-red-500">*</span> </label>
                        <input
                            type="text"
                            name="ownerFullName"
                            value={formData.ownerFullName}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                        {errors.ownerFullName && (
                            <span className="text-red-500 text-sm">{errors.ownerFullName}</span>
                        )}
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-1">Your Email <span className="text-red-500">*</span> </label>
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                        {errors.email && (
                            <span className="text-red-500 text-sm">{errors.email}</span>
                        )}
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-1">
                            Your Phone Number <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="tel"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            className={`w-full p-2 border ${errors.phoneNumber ? "border-red-500" : "border-gray-300"
                                } rounded-md`}
                        />
                        {errors.phoneNumber && (
                            <span className="text-red-500 text-sm">{errors.phoneNumber}</span>
                        )}
                    </div>

                    {/* Description */}
                    <div>
                        <label className="block text-sm font-medium mb-1">Description</label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>







                    {/* Latitude & Longitude */}
                    {/* {["latitude", "longitude"].map((field) => (
                        <div key={field}>
                            <label className="block text-sm font-medium mb-1">{capitalizeFirstLetter(field)}</label>
                            <input
                                type="text"
                                name={field}
                                value={formData[field]}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>
                    ))} */}

                    {/* Photo */}
                    {/* <div className="flex flex-col items-start">
                        <label className="block text-sm font-medium mb-2">Event Photo</label>
                        <div className="flex items-center w-full">
                            <label
                                htmlFor="photo"
                                className="cursor-pointer bg-black-600 hover:bg-black-900 text-white py-2 px-4 rounded-md shadow-md"
                            >
                                Upload Photo
                            </label>

                            <span className="ml-4 text-sm text-gray-500">
                                {formData.photo ? formData.photo.name : "No file selected"}
                            </span>
                        </div>
                        <input
                            type="file"
                            id="photo"
                            name="photo"
                            accept="image/*"
                            onChange={(e) =>
                                setFormData((prevState) => ({
                                    ...prevState,
                                    photo: e.target.files[0],
                                }))
                            }
                            className="hidden"
                        />
                    </div> */}




                    <div>
                        <h2 className="text-xl font-semibold mb-4">Sponsors</h2>
                        {formData.sponsors.map((sponsor, index) => (
                            <div key={index} className="flex flex-col mb-4">
                                <label className="text-sm font-medium mb-1">Sponsor Name</label>
                                <input
                                    type="text"
                                    name={`sponsors[${index}].name`}
                                    value={sponsor.name}
                                    onChange={(e) => handleArrayChange(e, "sponsors", index, "name")}
                                    className="p-2 border border-gray-300 rounded-md"
                                />

                                <label className="text-sm font-medium mt-2 mb-1">Sponsor Website</label>
                                <input
                                    type="text"
                                    name={`sponsors[${index}].webSite`}
                                    value={sponsor.webSite}
                                    onChange={(e) => handleArrayChange(e, "sponsors", index, "webSite")}
                                    className="p-2 border border-gray-300 rounded-md"
                                />


                                <div className="mt-4">
                                    <label className="text-sm font-medium mb-2">Sponsor Photo</label>
                                    <div className="relative">
                                        {/* Hidden File Input */}
                                        <input
                                            type="file"
                                            name={`sponsors[${index}].photo`}
                                            accept="image/*"
                                            onChange={(e) => handleArrayFileChange(e, "sponsors", index, "photo")}
                                            id={`sponsors-photo-${index}`}
                                            className="hidden"
                                        />

                                        {/* Styled Button */}
                                        <label
                                            htmlFor={`sponsors-photo-${index}`}
                                            className="cursor-pointer bg-black-600 hover:bg-black-900 text-white py-2 px-4 rounded-md shadow-md inline-block"
                                        >
                                            Upload Photo
                                        </label>
                                    </div>

                                    {/* File Name Display */}
                                    {formData.sponsors[index]?.photo && (
                                        <p className="mt-2 text-sm text-gray-600">
                                            Selected File: <span className="font-medium">{formData.sponsors[index].photo}</span>
                                        </p>
                                    )}
                                </div>


                                <button
                                    type="button"
                                    onClick={() => removeArrayItem("sponsors", index)}
                                    className="text-red-500 mt-2"
                                >
                                    Remove Sponsor
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={() => addArrayItem("sponsors", { name: "", webSite: "", photo: "" })}
                            className="mt-2 bg-black-500 text-white px-4 py-2 rounded-md"
                        >
                            Add Sponsor
                        </button>
                    </div>

                    <div>
                        <h2 className="text-xl font-semibold mt-6 mb-4">Hosts</h2>
                        {formData.hosts.map((host, index) => (
                            <div key={index} className="flex flex-col mb-4">
                                <label className="text-sm font-medium mb-1">Host Name</label>
                                <input
                                    type="text"
                                    name={`hosts[${index}].name`}
                                    value={host.name}
                                    onChange={(e) => handleArrayChange(e, "hosts", index, "name")}
                                    className="p-2 border border-gray-300 rounded-md"
                                />

                                <label className="text-sm font-medium mt-2 mb-1">Host Email</label>
                                <input
                                    type="email"
                                    name={`hosts[${index}].email`}
                                    value={host.email}
                                    onChange={(e) => handleArrayChange(e, "hosts", index, "email")}
                                    className="p-2 border border-gray-300 rounded-md"
                                />

                                <button
                                    type="button"
                                    onClick={() => removeArrayItem("hosts", index)}
                                    className="text-red-500 mt-2"
                                >
                                    Remove Host
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={() => addArrayItem("hosts", { name: "", email: "" })}
                            className="mt-2 bg-black-500 text-white px-4 py-2 rounded-md"
                        >
                            Add Host
                        </button>
                    </div>

                    <div>
                        <h2 className="text-xl font-semibold mt-6 mb-4">VIPs</h2>
                        {formData.vipUsers.map((vip, index) => (
                            <div key={index} className="flex flex-col mb-4">
                                <label className="text-sm font-medium mb-1">VIP Name</label>
                                <input
                                    type="text"
                                    name={`vipUsers[${index}].name`}
                                    value={vip.name}
                                    onChange={(e) => handleArrayChange(e, "vipUsers", index, "name")}
                                    className="p-2 border border-gray-300 rounded-md"
                                />

                                <label className="text-sm font-medium mt-2 mb-1">VIP Email</label>
                                <input
                                    type="email"
                                    name={`vipUsers[${index}].email`}
                                    value={vip.email}
                                    onChange={(e) => handleArrayChange(e, "vipUsers", index, "email")}
                                    className="p-2 border border-gray-300 rounded-md"
                                />

                                <button
                                    type="button"
                                    onClick={() => removeArrayItem("vipUsers", index)}
                                    className="text-red-500 mt-2"
                                >
                                    Remove VIP
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={() => addArrayItem("vipUsers", { name: "", email: "" })}
                            className="mt-2 bg-black-500 text-white px-4 py-2 rounded-md"
                        >
                            Add VIP
                        </button>
                    </div>


                </div>

                <button
                    type="submit"
                    className="mt-6 w-full bg-black-700 hover:bg-black-900 text-white py-2 px-4 rounded-lg"
                >
                    Submit
                </button>

            </form>
        </div>

    );
};

export default AddEventForm;
