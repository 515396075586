import React, { useEffect, useState } from "react";
import Loading from "react-fullscreen-loading";
import { toast } from "react-toastify";
import httpService from "services/http-service";
import AddEventForm from "./AddEventForm";
import Header from "components/header/HeaderAuthDefault";

const EventTable = () => {
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([

    ]);

    useEffect(() => {
        populateData();

    }, []);

    const populateData = async () => {
        setLoading(true);
        const endpoint = `/Event/GetEventsByUserId`;
        const response = await httpService("GET", endpoint);

        if (!response) {
            setLoading(false);
            toast.error('Something went wrong');
        } else {
            setLoading(false);
            setData(response || [])
        }
    };

    const [selectedEvent, setSelectedEvent] = useState(null); // For viewing/editing event
    const [isAddEvent, setIsAddEvent] = useState(false); // For viewing/editing event

    // Handle View
    const handleView = (event) => {
        setSelectedEvent(event);

    };
 

    // Handle Delete
    const closePopup = (id) => {
      setIsAddEvent(false);
      setSelectedEvent(null)
    };

    return (
        <div className="p-6  min-h-screen">
            <Header />
            <div className="flex items-center justify-between mb-4">
                <h1 className="text-2xl font-bold">Event List</h1>
                <button
                    onClick={() => setIsAddEvent(true)}
                    className="bg-black-500 text-white px-4 py-2 rounded hover:bg-black-800"
                >
                    Add Event
                </button>
            </div>


            <Loading loading={loading} loaderColor="#252424" />

            {/* Table */}
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                        <tr className="bg-gray-100 text-gray-700">
                            <th className="px-6 py-3 text-left text-sm font-medium">Name</th>
                            <th className="px-6 py-3 text-left text-sm font-medium">Location</th>
                            {/* <th className="px-6 py-3 text-left text-sm font-medium">State</th>
                            <th className="px-6 py-3 text-left text-sm font-medium">Phone</th> */}
                            <th className="px-6 py-3 text-left text-sm font-medium">Start Time</th>
                            <th className="px-6 py-3 text-left text-sm font-medium">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((event) => (
                            <tr
                                key={event.id}
                                className="border-b hover:bg-gray-50 transition-colors"
                                onClick={() => handleView(event)}
                            >
                                <td className="px-6 py-3 text-sm text-blue-400">{event.name}</td>
                                {/* <td className="px-6 py-3 text-sm"></td> */}
                                <td className="px-6 py-3 text-sm">{event.city} {event.stateProvince}</td>
                                {/* <td className="px-6 py-3 text-sm">{event.phoneNumber}</td> */}
                                <td className="px-6 py-3 text-sm">
                                    {new Date(event.startTime).toLocaleString()}
                                </td>
                                <td className="px-6 py-3 text-sm space-x-2">
                                    <button
                                        onClick={() => handleView(event)}
                                        className="text-blue-600 hover:underline"
                                    >
                                        View
                                    </button>
                                    {new Date(event.startTime).toLocaleString() > new Date().toLocaleString() && 
                                                                        <button
                                                                        onClick={() => handleView(event)}
                                                                        className="text-green-600 hover:underline"
                                                                    >
                                                                        Edit
                                                                    </button>
                                

                                    }
                                    {/* 
                                    <button
                                        onClick={() => handleDelete(event.id)}
                                        className="text-red-600 hover:underline"
                                    >
                                        Delete
                                    </button> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {isAddEvent && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-6xl w-full h-[90vh] flex flex-col">

                        <h2 className="text-2xl font-bold mt-8 mb-4">ADD Event to Spots</h2>
                        {/* Scrollable content */}
                        <div className="flex-1 overflow-y-scroll">
                            <AddEventForm  closePopup={closePopup} />
                        </div>

                        {/* Footer with a fixed button */}
                        <button
                            onClick={() => setIsAddEvent(false)}
                            className="mt-4 bg-black-500 text-white px-4 py-2 rounded hover:bg-black-600"
                        >
                            Close
                        </button>
                    </div>
                </div>

            )}
            {/* Modal for Viewing */}
            {selectedEvent && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-6xl w-full h-[90vh] flex flex-col">

                        <h2 className="text-2xl font-bold mt-8 mb-4">View / Update Event</h2>
                        {/* Scrollable content */}
                        <div className="flex-1 overflow-y-scroll">
                            <AddEventForm selectedEvent={selectedEvent} closePopup={closePopup} />
                        </div>

                        {/* Footer with a fixed button */}
                        <button
                            onClick={() => setSelectedEvent(null)}
                            className="mt-4 bg-black-500 text-white px-4 py-2 rounded hover:bg-black-600"
                        >
                            Close
                        </button>
                    </div>
                </div>

            )}
        </div>
    );
};

export default EventTable;
