import React from "react";
import logo from "assets/img/logo/logo.png";
export default function Header({ tagline, regHome = false }) {
  return (
    <div className="mt-[6px] mb-[4px] w-full max-w-full flex-col items-center">
      <div className="flex items-center justify-between text-2xl font-bold text-navy-700 dark:text-white">
        <img className="h-[33px] w-[112px]" src={logo} alt="logo" />
      </div>

      {tagline && (
        <p className=" text-sm font-medium text-navy-700 dark:text-white">
          {tagline}
        </p>
      )}
    </div>
  );
}
